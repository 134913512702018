import styled from "styled-components";
import Colors from "../../assets/color";
import { abrilFatface } from "../../assets/font";

export const StyledContainer = styled.div`
  width: 100%;
`;

export const Title = styled.p`
  margin: 12px auto;
  font-size: 25px;
  position: relative;
  height: 40px;
  line-height: 30px;
  padding: 6px 20px;
  border-radius: 50px;
  background-color: ${Colors.gray};
  color: ${Colors.dark_gray};
  font-family: ${abrilFatface};
`;
