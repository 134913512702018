import styled from "styled-components";
import Colors from "../../assets/color";
import { lato } from "../../assets/font";

export const BtnWrapper = styled.a`
  font-family: ${lato};
  text-transform: uppercase;
  text-decoration: none;
  color: ${Colors.main};
  font-size: 14px;
  height: 80px;
  line-height: 80px;

  &:hover {
    color: ${Colors.gold};
    transition: color 0.25s ease-in-out;
  }
  &:focus{
    outline: none;
  }
`;
