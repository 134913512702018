import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";

//Store slices
import languagesSlice from "./languages/languagesSlice";
import categoriesSlice from "./categories/categoriesSlice";
import totalSlice from "./total/totalSlice";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    languages: languagesSlice,
    categories: categoriesSlice,
    total: totalSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
export default store;
