import styled from "styled-components";
import Colors from "../../assets/color";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 25px;
  color: ${Colors.gray};
  font-size: 14px;
`;
