import React from "react";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import { MainWrapper, Title } from "../Main/styles";
import Category from "../Category/Category";

const LeftSide = ({ categories }) => {
  const { t } = useTranslation();
  //detects languages when changed get i18n from useTranslation
  // console.log(i18n.language);
  return (
    <Col md={8}>
      <MainWrapper>
        <Title>{t("price_calc")}</Title>
        {categories?.map((category) => (
          <Category key={category.id} category={category} />
        ))}
      </MainWrapper>
    </Col>
  );
};

export default LeftSide;
