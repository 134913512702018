import React, {useMemo} from "react";
import {Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {AnimatePresence} from "framer-motion";

//components
import PriceSummary from "../PriceSummary/PriceSummary";
import SuccessNotification from "../SuccessNotification/SuccessNotification";

//styles
import {
    ContactTitle,
    ContactWrapper,
    ErrorMessage,
    StyledBtn,
    StyledInput,
    SummaryWrapper,
    Title,
    Wrapper,
    PriceSummaryWrapper,
} from "./styles";

const RightSide = ({categories, showSuccess}) => {
    const {t} = useTranslation();
    const {total} = useSelector((state) => state.total);
    const {
        register,
        formState: {errors},
        setValue,
    } = useFormContext();

    const totalRes = useMemo(() => {
        const amount = total.reduce((sum, next) => sum + next.price, 0);
        setValue("total", amount);
        return amount;
    }, [total, setValue]);

    const totalDays = useMemo(
        () => {
            const totalDays = total.reduce((total, next) => total + next.days, 0);
            setValue('days', totalDays);
            return totalDays
        },
        [total, setValue]);
    return (
        <Col md={4}>
            <Wrapper>
                <SummaryWrapper>
                    <Title>{t("total")}</Title>
                    <PriceSummaryWrapper>
                        <p>{t("elaboration_period")}</p>
                        <p>{totalDays} {t('days')}</p>
                    </PriceSummaryWrapper>
                    {categories?.map((category) => (
                        <PriceSummary key={category.id} category={category} total={total}/>
                    ))}

                    <PriceSummaryWrapper>
                        <p>{t("total")}</p>
                        <p>{totalRes}€</p>
                    </PriceSummaryWrapper>
                </SummaryWrapper>
                <ErrorMessage>{errors.services?.message}</ErrorMessage>
                <ContactWrapper>
                    <ContactTitle>{t("contact")}</ContactTitle>
                    <StyledInput
                        type="text"
                        autoComplete={"off"}
                        placeholder={t("name")}
                        {...register("name")}
                    />
                    <ErrorMessage>{errors.name?.message}</ErrorMessage>
                    <StyledInput
                        type="mail"
                        autoComplete={"off"}
                        placeholder={t("email")}
                        {...register("email")}
                    />
                    <ErrorMessage>{errors.email?.message}</ErrorMessage>
                    <StyledInput
                        type="text"
                        autoComplete={"off"}
                        placeholder={t("phone")}
                        {...register("phone")}
                    />
                    <ErrorMessage>{errors.phone?.message}</ErrorMessage>
                    <input type="hidden" {...register("total")} />
                    <StyledBtn type={"submit"}>{t("submit")}</StyledBtn>
                    <AnimatePresence>
                        {showSuccess && <SuccessNotification/>}
                    </AnimatePresence>
                </ContactWrapper>
            </Wrapper>
        </Col>
    );
};

export default RightSide;
