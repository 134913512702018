import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchLanguagesStart,
  fetchLanguagesSuccess,
  fetchLanguagesError,
} from "./languagesSlice";
import { getAllLanguagesRequest } from "./sagaRequest";

function* fetchLanguageSagaWorker() {
  try {
    const res = yield call(getAllLanguagesRequest);
    yield put(fetchLanguagesSuccess(res.data));
  } catch (e) {
    const message =
      e.response && e.response.data.message
        ? e.response.data.message
        : e.message;
    yield put(fetchLanguagesError(message));
  }
}

export function* fetchLanguageSagaWatcher() {
  yield takeLatest(fetchLanguagesStart.type, fetchLanguageSagaWorker);
}
