import axios from "axios";
import {getAllCategoriesApi, sendContactFormApi} from "../../api";

export const getAllCategoriesRequest = (locale) =>
    axios.get(getAllCategoriesApi(), {
        headers: {
            "Accept-Language": locale,
        },
    });

export const sendContactFormRequest = ({data, locale}) =>
    axios.post(sendContactFormApi(), data, {
        headers: {
            "Accept-Language": locale,
        },
    });
