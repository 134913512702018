import * as yup from "yup";

const schema = yup.object({
  email: yup.string().email().required().label("Email"),
  name: yup.string().required().label("Name"),
  phone: yup.string().required().label("Phone"),
  services: yup.array().min(1).label("Service"),
});

export default schema;
