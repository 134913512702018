import styled from "styled-components";

export const MainWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-size: 40px;
  line-height: 44px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  padding: 10px 0 70px;
  margin: 0;
`;
