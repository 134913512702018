import React from "react";
import i18next from "i18next";
import { BtnWrapper } from "./styles";

const LangBtn = ({ lang }) => {
  const changeLang = () => {
    i18next.changeLanguage(lang.code);
  };
  return (
    <BtnWrapper href={"#!"} onClick={changeLang}>
      {lang.code}
    </BtnWrapper>
  );
};

export default LangBtn;
