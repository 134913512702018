import React from "react";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import image from "../../assets/img/small50.png";
import { LangContainer, StyledRow } from "./styles";
import LangBtn from "../LangBtn/LangBtn";

const Header = () => {
  const { languages } = useSelector((state) => state.languages);
  return (
    <StyledRow>
      <Col md={8}>
        <img className={"logo"} src={image} alt="" />
      </Col>
      <Col md={3}>
        <LangContainer>
          {languages?.map((lang) => (
            <LangBtn key={lang.code} lang={lang} />
          ))}
        </LangContainer>
      </Col>
      <Col md={1} />
    </StyledRow>
  );
};

export default Header;
