import styled from "styled-components";
import {Row} from "react-bootstrap";

export const StyledRow = styled(Row)`
  height: 80px;
  margin: 0;
  background-color: #fff;

  .logo {
    margin: 20px 0 0 4rem;
    width: 45px;
    height: 45px;
  }
`;

export const LangContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
 padding-right: 100px;
  align-items: center;
`;