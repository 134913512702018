import { createSlice } from "@reduxjs/toolkit";
import initState from "./initState";

const categoriesSlice = createSlice({
  name: "category",
  initialState: initState,
  reducers: {
    fetchCategoriesStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchCategoriesSuccess: (state, action) => {
      state.categories = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchCategoriesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    sendFormStart: (state) => {
      state.loading = true;
      state.error = null;
    },

    sendFormSuccess: (state) => {
      state.loading = false;
      state.error = null;
      state.formSent = true;
      state.showSuccess = true;
    },
    setFormSentToFalse: (state) => {
      state.formSent = false;
    },
    setShowSuccessNotificationFalse: (state) => {
      state.showSuccess = false;
    },
    deleteError: (state) => {
      state.error = null;
    },
  },
});

export const {
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  fetchCategoriesError,
  sendFormStart,
  sendFormSuccess,
  setFormSentToFalse,
  deleteError,
  setShowSuccessNotificationFalse,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
