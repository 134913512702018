import { createSlice } from "@reduxjs/toolkit";
import initState from "./initState";

const totalSlice = createSlice({
  name: "total",
  initialState: initState,
  reducers: {
    addService: (state, action) => {
      state.total.push(action.payload);
    },
    removeService: (state, action) => {
      state.total = state.total.filter((item) => item.id !== action.payload);
    },
    emptyServices: (state) => {
      state.total = [];
    },
  },
});

export const { addService, removeService, emptyServices } = totalSlice.actions;
export default totalSlice.reducer;
