import styled from "styled-components";
import Colors from "../../assets/color";

export const Wrapper = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  width: 70%;
  padding: 10px 40px 10px 10px;
  background-color: aliceblue;
  text-align: center;
  border-radius: 20px;
  box-shadow: 2px 2px 8px ${Colors.main};

  p {
    margin: 0;
    color: ${Colors.red};
  }
`;

export const SvgWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;
