import React, { useMemo } from "react";
import { Wrapper } from "./styles";

const PriceSummary = ({ category, total }) => {
  const servicesArr = total.filter((item) => item.category_id === category.id);
  const res = useMemo(
    () => servicesArr.reduce((total, next) => total + next.price, 0),
    [servicesArr]
  );
  return (
    <Wrapper>
      <p>{category?.title}</p>
      <p>{res}€</p>
    </Wrapper>
  );
};

export default PriceSummary;
