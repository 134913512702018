import React from "react";
import { useTranslation } from "react-i18next";

import { appear } from "./animation";
import { Wrapper } from "./styles";

const SuccessNotification = () => {
  const { t } = useTranslation();
  return (
    <Wrapper
      variants={appear}
      initial={"hidden"}
      animate={"show"}
      exit={"exit"}
    >
      <p>{t("success_from_sent")}</p>
    </Wrapper>
  );
};

export default SuccessNotification;
