import styled from "styled-components";
import { motion } from "framer-motion";
import Colors from "../../assets/color";

export const Wrapper = styled(motion.div)`
  width: 100%;
  padding: 10px 40px 10px 10px;
  background: linear-gradient(to bottom right, #b0db7d 40%, #99dbb4 100%);
  text-align: center;
  border-radius: 20px;
  box-shadow: 2px 2px 8px ${Colors.main};
  margin-top: 10px;

  p {
    margin: 0;
    color: ${Colors.main};
  }
`;
