import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "en",
    detection: {
      // order and from where user language should be detected
      order: ["cookie", "localStorage"],
      // cache user language on
      caches: ["cookie", "localStorage"],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      //Files for translation
      loadPath: "/lang/{{lng}}/translation.json",
    },
  });

export default i18n;
