export default Object.freeze({
  main: "#414141",
  secondary: "#363636",
  white: "#fff",
  gray: "#E5E5E5",
  dark_gray: "#707070",
  red: "#fd0000",
  gold: "#cfa144",
  black: "#000",
});
