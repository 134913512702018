import { all, fork } from "redux-saga/effects";
import * as languageSaga from "./languages/saga";
import * as categoriesSaga from "./categories/saga";

function* rootSaga() {
  yield all(
    [...Object.values(languageSaga), ...Object.values(categoriesSaga)].map(fork)
  );
}

export default rootSaga;
