import React from "react";
import {useFormContext} from "react-hook-form";
import {useDispatch} from "react-redux";

//redux actions
import {addService, removeService} from "../../store/total/totalSlice";

//styles
import {
    BottomWrapper,
    CheckboxWrapper,
    Description,
    ExampleLink,
    Input,
    Label,
    ServiceContainer,
    Tick,
    Title,
    TopWrapper,
} from "./style";

const Service = ({service}) => {
    const {getValues, setValue} = useFormContext();
    const dispatch = useDispatch();
    const handleChange = (e) => {
        const services = getValues("services");
        const {value} = e.target;
        if (e.target.checked) {
            services.push(value);
            setValue("services", services);
            dispatch(
                addService({
                    id: Number(value),
                    category_id: service.category_id,
                    price: service.price,
                    days: service.days
                })
            );
            return;
        }
        const newServices = services.filter((serviceItem) => serviceItem !== value);
        setValue("services", newServices);
        dispatch(removeService(Number(value)));
        return;
    };
    return (
        <ServiceContainer>
            <TopWrapper>
                <Title>{service.text[0]?.title}</Title>
                <p>
                    {service.price}
                    {service.text[0]?.price_tag}
                </p>
                <CheckboxWrapper>
                    <Input
                        id={service.id}
                        type="checkbox"
                        value={service.id}
                        onClick={handleChange}
                    />
                    <Label htmlFor={service.id}>
                        <Tick/>
                    </Label>
                </CheckboxWrapper>
            </TopWrapper>
            <BottomWrapper>
                <Description>{service.text[0]?.description}</Description>
                {service?.has_example && (
                    <ExampleLink href={service?.text[0].example_link} target={"_blank"}>
                        Example
                    </ExampleLink>
                )}
            </BottomWrapper>
        </ServiceContainer>
    );
};

export default Service;
