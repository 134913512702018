export const appear = {
  hidden: {
    x: 200,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.25,
    },
  },
  exit: {
    x: 200,
    opacity: 0,
    transition: {
      duration: 0.25,
    },
  },
};
