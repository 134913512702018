import { createSlice } from "@reduxjs/toolkit";
import initState from "./initState";

const languagesSlice = createSlice({
  name: "languages",
  initialState: initState,
  reducers: {
    fetchLanguagesStart: (state) => {
      state.loading = true;
    },
    fetchLanguagesSuccess: (state, action) => {
      state.languages = action.payload;
      state.loading = false;
    },
    fetchLanguagesError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchLanguagesStart,
  fetchLanguagesSuccess,
  fetchLanguagesError,
} = languagesSlice.actions;
export default languagesSlice.reducer;
