import styled from "styled-components";
import Colors from "../../assets/color";
import { abrilFatface } from "../../assets/font";

export const ServiceContainer = styled.div`
  padding: 0 5%;
  margin: 2rem 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 5%;
    background: linear-gradient(
      to right,
      ${Colors.main} 20%,
      ${Colors.white} 80%
    );
    display: block;
    height: 2px;
    width: 80%;
  }
`;

export const Title = styled.p`
  padding-bottom: 3px;
  font-family: ${abrilFatface};
  font-size: 20px;
  letter-spacing: 1px;
  color: ${Colors.dark_gray};
  margin-bottom: 0;
`;

export const Input = styled.input`
  display: none;
`;

export const Tick = styled.div`
  position: relative;
  right: calc(25px * -0.5);
  top: calc(25px * -0.25);
  width: calc(25px * 0.25);
  height: calc(25px * 0.75);
  border-right: calc(25px * 0.25) solid ${Colors.gold};
  border-bottom: calc(25px * 0.25) solid ${Colors.gold};
  transform: rotate(45deg) scale(0);
  opacity: 0;
  transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.5);

  &:before {
    content: "";
    position: absolute;
    left: calc(25px * -0.125);
    bottom: calc(25px * -0.25);
    border: calc(25px * 0.125) solid ${Colors.gold};
    border-radius: 50%;
  }

  &:after {
    content: "";
    position: absolute;
    right: calc(25px * -0.25);
    top: calc(25px * -0.125);
    border: calc(25px * 0.125) solid ${Colors.gold};
    border-radius: 50%;
  }
`;

export const Label = styled.label`
  display: inline-block;
  margin: 0 calc(25px * 0.25);
  width: 25px;
  height: 25px;
  border: calc(25px * 0.125) solid ${Colors.white};
  border-radius: 12.5%;
  transition: 400ms 100ms ease-out;
  border-color: ${Colors.dark_gray};
  cursor: pointer;
`;

export const CheckboxWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  ${Input}:checked + ${Label} ${Tick} {
    opacity: 1;
    transform: rotate(45deg) scale(1);
  }
`;

export const TopWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
`;

export const BottomWrapper = styled.div`
  width: 80%;
  display: block;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

export const Description = styled.p`
  color: ${Colors.dark_gray};
`;

export const ExampleLink = styled.a`
  color: ${Colors.red};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${Colors.gold};
    transition: color 0.25s ease-in-out;
  }
`;
