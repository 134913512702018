import styled from "styled-components";
import Colors from "../../assets/color";
import { lato, montserrat } from "../../assets/font";

export const Wrapper = styled.div`
  width: inherit;
  position: fixed;
  top: 4rem;
  right: 1rem;
`;

export const Title = styled.h2`
  font-size: 34px;
  line-height: 38px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: ${montserrat};
  text-align: center;
  color: ${Colors.secondary};
`;

export const SummaryWrapper = styled.div`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 10%;
    height: 2px;
    width: 80%;
    display: block;
    background: radial-gradient(${Colors.dark_gray} 20%, #fff 80%);
  }
`;

export const PriceSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 25px;
  color: ${Colors.gray};
`;

export const ContactWrapper = styled.div``;

export const ContactTitle = styled.div`
  text-align: center;
  padding-top: 10px;
  font-size: 28px;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: 400;
  color: ${Colors.secondary};
  font-family: "Montserrat", sans-serif;
`;

export const StyledInput = styled.input`
  display: block;
  margin: 8px auto 0px;
  width: 80%;
  border: none;
  border-bottom: 2px solid ${Colors.dark_gray};

  &:focus {
    outline: none;
  }
`;

export const StyledBtn = styled.button`
  background: ${Colors.black};
  margin-top: 20px;
  border: none;
  color: ${Colors.white};
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  line-height: normal;
  padding: 15px 25px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 100%;
  border-radius: 50px;
  font-family: ${lato};
  font-weight: 400;

  &:hover {
    color: ${Colors.gold};
    transition: color 0.25s ease-in-out;
  }
`;

export const ErrorMessage = styled.p`
  color: ${Colors.red};
  text-align: center;
`;
