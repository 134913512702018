import React from "react";
import loader from "../../assets/img/loader.gif";
import { Wrapper } from "./styles";

const Loading = () => {
  return (
    <Wrapper>
      <img src={loader} alt="loading" />
    </Wrapper>
  );
};

export default Loading;
