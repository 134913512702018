import React, {useEffect} from "react";
import {Container, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useForm, FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
//redux action
import {fetchLanguagesStart} from "../../store/languages/languagesSlice";
import {
    fetchCategoriesStart,
    sendFormStart,
    setFormSentToFalse,
} from "../../store/categories/categoriesSlice";

//components
import LeftSide from "../LeftSide/LeftSide";
import RightSide from "../RightSide/RightSide";
import Loading from "../Loading/Loading";

import schema from "../../validation/schema";
import ErrorNotification from "../ErrorNotification/ErrorNotification";

const Main = () => {
    const {i18n} = useTranslation();
    const dispatch = useDispatch();
    const {categories, loading, error, formSent, showSuccess} = useSelector(
        (state) => state.categories
    );
    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            days: 0,
            total: 0,
            services: [],
        },
    });
    useEffect(() => {
        dispatch(fetchLanguagesStart());
    }, [dispatch]);
    useEffect(() => {
        dispatch(fetchCategoriesStart(i18n.language));
    }, [i18n.language, dispatch]);
    useEffect(() => {
        if (formSent) {
            methods.reset({
                days: 0,
                total: 0,
                services: [],
            });
            dispatch(setFormSentToFalse());
            // window.location.reload();
        }
    }, [formSent, dispatch, methods]);
    const handleFormSubmit = (data) => {
        dispatch(sendFormStart({data: data, locale: i18n.language}));
    };
    return (
        <Container fluid>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
                    <Row>
                        <LeftSide categories={categories}/>
                        <RightSide categories={categories} showSuccess={showSuccess}/>
                    </Row>
                </form>
            </FormProvider>
            {loading && <Loading/>}
            {error && <ErrorNotification message={error}/>}
        </Container>
    );
};

export default Main;
