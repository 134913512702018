import {takeEvery, call, put, takeLatest, delay} from "redux-saga/effects";
import {
    fetchCategoriesStart,
    fetchCategoriesSuccess,
    fetchCategoriesError,
    sendFormStart,
    sendFormSuccess,
    setShowSuccessNotificationFalse,
} from "./categoriesSlice";
import {getAllCategoriesRequest, sendContactFormRequest} from "./sagaRequest";
import {emptyServices} from "../total/totalSlice";

//fetch all categories from api
function* fetchCategoriesWorker(data) {
    try {
        const res = yield call(getAllCategoriesRequest, data.payload);
        yield put(fetchCategoriesSuccess(res.data));
    } catch (e) {
        const message =
            e.response && e.response.data.message
                ? e.response.data.message
                : e.message;
        yield put(fetchCategoriesError(message));
    }
}

export function* fetchCategoryWatcher() {
    yield takeEvery(fetchCategoriesStart.type, fetchCategoriesWorker);
}

//send form to DB
function* sendFormWorker(data) {
    try {
        const res = yield call(sendContactFormRequest, data.payload);
        if (res.status === 204) {
            yield put(sendFormSuccess());
            yield put(emptyServices());
            yield delay(2000);
            yield put(setShowSuccessNotificationFalse());
        }
    } catch (e) {
        const message =
            e.response && e.response.data.message
                ? e.response.data.message
                : e.message;
        yield put(fetchCategoriesError(message));
    }
}

export function* sendFormWatcher() {
    yield takeLatest(sendFormStart.type, sendFormWorker);
}
