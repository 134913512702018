import React from "react";
import { StyledContainer, Title } from "./styles";
import Service from "../Service/Service";

const Category = ({ category }) => {
  return (
    <StyledContainer>
      <Title>{category.title}</Title>
      {category.services?.map((service) => (
        <Service service={service} key={service.id} />
      ))}
    </StyledContainer>
  );
};

export default Category;
